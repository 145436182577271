export const RFetch = async (url: string, mtd: string = "GET", data = {}) => {
    const config = useRuntimeConfig();
    const token = useCookie("_xloginsession");
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token.value}`,
    };
    url = `${url}`;
    // if (process.env.NODE_ENV === 'development') {
    //     url = `${config.public.api_url}/api${url}`
    // } else {
    //     const host = window.location.host
    //     const protocol = window.location.protocol
    //     url = `${protocol}//${host}/api${url}`
    // }
    if (!url.includes("http")) {
        url = `${config.public.api_url}/api${url}`;
    }
    try {
        let res = null;
        if (mtd === "GET") {
            res = await $fetch(url, {
                method: mtd,
                headers,
            });
        } else {
            res = await $fetch(url, {
                method: mtd,
                headers,
                body: data,
            });
        }
        return {
            message: "",
            status: "success",
            data: res,
        };
    } catch (error) {
        // Here you can handle different types of errors and return appropriate messages
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            return {
                status: "error",
                message: `Error: ${error.response.statusText}`,
                code: error.response.status,
            };
        } else if (error.request) {
            // The request was made but no response was received
            return {
                status: "error",
                message: "Error: No response received from server.",
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            return {
                status: "error",
                message: `Error: ${error.message}`,
            };
        }
    }
};
